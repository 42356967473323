import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TokenStorageService } from '@auth/services/token-storage.service';
import Bond from '@shared/models/Bond';
import { StatusService } from '@shared/translations/status.service';

@Component({
  selector: 'app-bond',
  templateUrl: './bond.component.html',
  styleUrls: ['./bond.component.scss'],
})
export class BondComponent implements OnInit {

  @Input() role!: string;

  @Input() public bond!: Bond | Bond[] | null;

  @Input() public comingFromTransaction = false;

  bonds: Bond[] = [];

  public isAdmin!: boolean;

  constructor(
    public status: StatusService,
    private storage: TokenStorageService) { }

  ngOnInit() {
    this.processBond();
    this.isAdmin = this.storage.getRoles().find((role) => role === 'ROLE_ADMIN') !== undefined;

  }

  private processBond() {
    if (Array.isArray(this.bond)) {
      this.bonds = this.bond;
    } else if (this.bond) {
      this.bonds.push(this.bond);
    }
  }

  onClick() {
  }
}
